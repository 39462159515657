import React from 'react';
import S from 'StyledChangePasswordForm.js';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {translation} from 'helpers/utilsHelper.js';
import ModalOpacity from 'ModalOpacity.js';
import useToggle from 'UseToggle.js'
import _get from 'lodash/get';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validators.enum.js';

const validate = values => {
    const errors = validation(values, validators.CHANGE_PASSWORD);
    return errors;
};

const renderField = ({
    input,
    label,
    type,
    meta: {touched, error, warning}
}) => {
    return (
        <S.Row>
            <S.InputWrapper>
                <S.Input {...input} type={type} required="required"/>
                <S.Label>{label}</S.Label>
                {touched && ((error && <S.Error>{error[0]}</S.Error>) || (warning && <S.Error>{warning}</S.Error>))}
            </S.InputWrapper>
        </S.Row>
    )
};

const ChangePasswordForm = ({changePasswordOnSubmit, handleSubmit, userId, pristine, submitting, error}) => {

    const [showSuccessModal, toggleSuccessModal] = useToggle(false);

    const onFormSubmit = async(values) => {

        try {
            const params = {
                oldValue: _get(values, 'oldPassword'),
                newValue: _get(values, 'newPassword'),
                customerId: userId
            };

            await changePasswordOnSubmit(params);
            toggleSuccessModal();
        } catch (err) {
            throw new SubmissionError({_error: err});
        }
    };

    return (
        <S.ChangePasswordForm method="post">

            <ModalOpacity isOpen={showSuccessModal} toggleOpen={toggleSuccessModal} showHeaderIcon={false} showHeaderTitle={false}>
                <S.SuccessModal>
                    <S.SuccessModalTitle>{translation('account_changePasswordForm_successTitle')}</S.SuccessModalTitle>
                    <S.SuccessModalMessage>{translation('account_changePasswordForm_success')}</S.SuccessModalMessage>
                </S.SuccessModal>
            </ModalOpacity>

            <Field
                name="oldPassword"
                type="password"
                component={renderField}
                label={translation('account_changePasswordForm_oldPassword')}
            />
            <Field
                name="newPassword"
                type="password"
                component={renderField}
                label={translation('account_changePasswordForm_newPassword')}
            />
            <Field
                name="confirmPassword"
                type="password"
                component={renderField}
                label={translation('account_changePasswordForm_confirmNewPassword')}
            />

            {error && <S.SubmissionError className="submission-error">{error}</S.SubmissionError>}

            <S.SubmitBtn onClick={handleSubmit(onFormSubmit)} disabled={pristine || submitting}>{translation('account_changePasswordForm_changePassword')}</S.SubmitBtn>

        </S.ChangePasswordForm>
    );
}

export default reduxForm({
    form: 'changePasswordForm',
    validate
})(ChangePasswordForm)
