import React, { Component } from 'react';
import { connect } from 'react-redux';
import S from 'StyledProviderContent.js';
import { cmsPage } from 'cmsActions.js';
import { translation, pushEventToGoogleAnalytics } from 'helpers/utilsHelper.js';
import { activateSportRadarEventsTrack } from 'integrationActions.js';
import paymentPrepareTransaction from 'paymentPrepareTransaction.enum.js';
import {
    creditCardList,
    paystackBankList,
    bankList,
    accountTypes,
    sendPaymentData,
    customerMobileAccount,
    wonAmountToPayout,
    customerBankDetail,
    customerCreditCardUpdate,
    winningsBalance
} from 'paymentActions.js';
import { offersCanceledOnWithdrawal } from 'offersActions.js';
import { reduxForm, reset, formValueSelector } from 'redux-form';
import Loader from 'Loader.js';
import { NotLogged } from 'global/styles/styles.js';
import Notifications, { addNotification, removeNotification } from 'Notifications.js';

// Micro Components
import {
    Item,
    GroupComponents,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    ImgAndTextComponent,
    ImageComponent,
    ConfirmRowComponent,
    ConfirmDataComponent,
    CmsComponent,
    LimitsComponent,
    MenuComponent,
    LockComponent,
    ExternalComponent,
    TextComponent,
    IframeComponent,
    ConfirmOffersCanceledOnWithdrawalComponent,
    InfoWonAmountToPayoutComponent,
    RemoveCardCardComponent,
    WinningsBalanceComponent,
    ReactSelectComponent
} from 'ProviderMicroComponents.js';

// Validation
import validation from 'sb-validate-js/validate.js';
import validators from 'validatorsPayments.enum.js';


class ProviderContent extends Component {

    constructor(props) {
        super(props);
        this.toggleFormValid = React.createRef();
        this.notificationDOMRef = React.createRef();
        this.state = this.getInitialState();
    }

    componentDidMount = async () => {
        const { dispatch, providerForm, isLogged } = this.props;

        try {
            this.drawComponents();

            if (providerForm && 'autoInitialize' in providerForm && isLogged) {
                dispatch(this.props.handleSubmit(this.sendData));
            }
        } catch (error) {
            this.showLogAndNotification('componentDidMount', error);
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.externalEvent && prevProps.externalEvent.resetProvider !== this.props.externalEvent.resetProvider) {
            this.resetProvider();
        }

        if (this.toggleFormValid.current && (this.props.invalid !== prevProps.invalid || !this.props.invalid)) {
            this.toggleFormValid.current.disabled = this.props.invalid;
        }
    }

    getInitialState = () => {
        let initialize = null;

        if (this.props.providerForm && 'initialize' in this.props.providerForm) {
            initialize = this.props.providerForm.initialize;
        }

        const initialState = {
            step: 1,
            drawing: [],
            insideDrawing: [],
            confrimData: {},
            active: false,
            responseData: {},
            postData: JSON.parse(JSON.stringify(initialize)),
            callData: {},
        }

        return initialState;
    }

    updatePostData = async(formData) => {
        let { postData, confrimData } = this.state;

        for (let item in formData) {
            if (formData[item] == '') {

                continue;

            } else if (item.includes('->')) {

                let pathName = this.splitPath(item);
                let pathValue = this.splitPath(formData[item]);

                if (pathName && pathName.size == 2 && pathValue && pathValue.size == 2) {

                    if (!(pathName.part[0] in postData)) {
                        await this.setState(state => (postData[pathName.part[0]] = {}, state));
                    }

                    this.setState(state => (postData[pathName.part[0]][pathName.part[1]] = pathValue.part[0], state));
                    this.setState(state => (confrimData[pathName.part[1]] = pathValue.part[1], state));

                } else if (pathName && pathName.size == 2 && pathValue == undefined) {

                    if (!(pathName.part[0] in postData)) {
                        await this.setState(state => (postData[pathName.part[0]] = {}, state));
                    }

                    this.setState(state => (postData[pathName.part[0]][pathName.part[1]] = formData[item], state));
                    this.setState(state => (confrimData[pathName.part[1]] = formData[item], state));

                }

            } else {

                this.setState(state => (postData[item] = formData[item], state));
                this.setState(state => (confrimData[item] = formData[item], state));

            }

        }

    }

    splitPath = (name) => {
        let path = name.split('->');
        if (path.length > 1) {
            return {
                part: path,
                size: path.length
            };
        }
    }

    combineData = (data) => {
        const { name, value } = data;
        this.setState(state => (state.postData[name] = value, state));
    }

    combinePathData = (data) => {
        const { path, name, value } = data;
        this.setState(state => (state.postData[path][name] = value, state));
    }

    sendData = async (formData) => {
        let { postData, step } = this.state;
        const { paymentData: { isDeposit }, providerForm: { insideIframe, submitHiddenForm }, userData: { userId }, dispatch } = this.props;
        let { providerForm: { steps }} = this.props;
        const {sportRadarEventsTrack, facebookPixel} = paymentPrepareTransaction.LIST_SETTINGS;

        removeNotification();
        await this.updatePostData(formData);
        formData = this.findPostData(formData);
        this.setState({ drawing: [<Loader />] })

        try {
            const result = await dispatch(sendPaymentData((
                { 'process': {
                    ...postData,
                    iframeDomain: app.config.domainUrl
                } }[this.convertStep()] ||
                postData
            ), this.convertStep()))
            const checkResult = this.checkResponse(result);

            if (checkResult && 'steps' in checkResult) {
                steps = checkResult.steps;
            }

            const { success, externalRequestUrl, externalRequestForm, responseCode, remoteResponseCode, transactionAmount, transactionCurrency } = result;

            if (success && step != steps) {
                this.setState({ step: step + 1, drawing: [], insideDrawing: [] })
                this.setState(this.drawComponents());

            } else if (success && step == steps) {

                if (isDeposit && app.config.dataLayerEvents.indexOf('deposit-success') > -1) {
                    pushEventToGoogleAnalytics('deposit-success', transactionAmount ? transactionAmount.toFixed(2) : formData.transactionAmount);
                }
                if (isDeposit && sportRadarEventsTrack) {
                    activateSportRadarEventsTrack(
                        "deposit", 
                        "betting", {
                            userId: userId, 
                            amount: transactionAmount ? transactionAmount : postData.transactionAmount, 
                            currency: transactionCurrency ? transactionCurrency: postData.transactionCurrency
                        }
                    );
                }

                if (isDeposit && facebookPixel) {
                    fbq('track', 'Purchase', {
                            currency: transactionCurrency ? transactionCurrency: postData.transactionCurrency, 
                            value: transactionAmount ? transactionAmount : postData.transactionAmount,
                        }    
                    );
                }
                
                if (externalRequestUrl) {
                    insideIframe ? this.renderIframe(externalRequestUrl, submitHiddenForm) : window.location = externalRequestUrl;
                } else if (externalRequestForm) {
                    this.goToExternalForm(externalRequestForm);
                } else {
                    addNotification('success', translation('payments_success'), translation('payments_success_' + this.paymentType(isDeposit)));
                    this.resetProvider();
                }

            } else {
                if (remoteResponseCode) {
                    addNotification('danger', translation('payments_error'), translation('payments_error_' + remoteResponseCode));
                } else if (responseCode) {
                    addNotification('danger', translation('payments_error'), translation('payments_error_' + responseCode));
                }
                this.resetProvider();
            }

        } catch (error) {
            this.showLogAndNotification('sendData', error);
        }
    }

    paymentType = (isDeposti) => {
        return isDeposti ? 'deposit' : 'payout';
    }

    checkResponse = (result) => {
        this.setState({ responseData: result });

        const { userData, providerForm: { steps, renderAccount } } = this.props;

        if (steps == 3 && 'transactionId' in result) {
            this.setState(state => (state.postData['transactionId'] = result.transactionId, state));
        }

        if ('bank' in result && renderAccount) {
            const { bank } = result;

            for (const key in bank) {
                this.setState(state => (state.confrimData[key] = bank[key], state));
            }

            if (userData) {
                const { firstName, lastName } = userData;
                this.setState(state => (state.confrimData['accountName'] = `${firstName} ${lastName}`, state));
            }
        }

        if ('remoteResponseCode' in result && result.remoteResponseCode == '1023') {
            this.setState(state => (state.postData['transactionId'] = result.transactionId, state));
            return {
                steps: 3
            }
        }

         
    }

    goToExternalForm = (form) => {
        this.setState({ drawing: [<ExternalComponent item={form} />] });
        const externalForm = document.getElementById('externalForm');
        externalForm.children[0].submit();
    }

    renderIframe = (url, submitHiddenForm) => {
        this.setState({ drawing: [<IframeComponent url={url} />] });
        if (submitHiddenForm) {
            //submit form
        }
    }

    convertStep = () => {
        let { step } = this.state;

        if (step == 1) {
            return 'initialize';
        } else if (step == 2) {
            return 'process';
        } else {
            return 'verify';
        }
    }

    setNewPinCode = () => {

        const conditionalDrawing = [];

        this.setState({ drawing: conditionalDrawing });

        conditionalDrawing.push(<TextComponent
            label={'payment_setNewPinCodeInfo'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'oldSecurityToken'}
            type={'password'}
            label={'payment_oldPinCode'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'newSecurityToken'}
            type={'password'}
            label={'payment_newPinCode'}
        />);

        conditionalDrawing.push(<InputComponent
            name={'confirmNewSecurityToken'}
            type={'password'}
            label={'payment_confirmNewPinCode'}
        />);

        conditionalDrawing.push(<ButtonComponent
            label={'payment_changePinCode'}
            onClick={'submit'}
        />);

        this.setState({ drawing: conditionalDrawing, step: 2 })
    }

    changePinCode = async () => {
        const { dispatch } = this.props;

        const pinData = {
            oldSecurityToken: this.state.postData.oldSecurityToken,
            newSecurityToken: this.state.postData.oldSecurityToken
        }
        const result = await dispatch(changePinCode(pinData));
        alert(result);
    }

    drawComponents = async (itemsRecursive) => {
        const { providerForm, isLogged, paymentData: { settings } } = this.props;
        const { mustBeLogged } = this.checkProviderSettings(settings);

        if (providerForm && (!mustBeLogged || isLogged)) {

            const { providerForm: { stepOne, stepTwo, stepThree, callData }, paymentData: { providerId } } = this.props;
            const { step, drawing, insideDrawing } = this.state;

            if ('callData' in providerForm && itemsRecursive == undefined) {
                if (Array.isArray(callData)) {
                    for (let item of callData) {
                        await this.findAssignedFunction(item, providerId);
                    }
                } else {
                    await this.findAssignedFunction(callData, providerId);
                }
            }

            if (step == 1 && itemsRecursive == undefined) {
                for (let item of stepOne) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }

            } else if (step == 2 && itemsRecursive == undefined) {
                for (let item of stepTwo) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }
            } else if (step == 3 && itemsRecursive == undefined) {
                for (let item of stepThree) {
                    this.setState({ drawing: this.findRequiredComponent(item, drawing) });
                }
            } else {
                for (let item of itemsRecursive) {
                    this.setState({ insideDrawing: this.findRequiredComponent(item, insideDrawing) });
                }

            }
        } else if (mustBeLogged && !isLogged) {
            this.setState({ drawing: [<NotLogged>{translation('payment_notLogged')}</NotLogged>] });
        }
    }

    findRequiredComponent = (item, drawing) => {
        const keyIndex = 0;

        switch (Object.keys(item)[keyIndex]) {
            case 'ImageComponent': {
                drawing.push(<ImageComponent src={item.ImageComponent} />);
                break;
            }
            case 'ImgAndTextComponent': {
                const { label, imageComponent } = item.ImgAndTextComponent;
                drawing.push(<ImgAndTextComponent
                    imageComponent={imageComponent}
                    label={label}
                />);
                break;
            }
            case 'InputComponent': {
                let { placeholder, required, onChange, type, name, label, disabled, limitsProvider, className, ifInitialValueDisabled } = item.InputComponent;

                if (onChange) {
                    onChange = this.findAssignedFunction(onChange);
                }
                
                const { paymentData: { limits, isDeposit }, userData } = this.props;

                if (name == 'transactionAmount') {
                    limitsProvider = this.setLimitsProvider(limitsProvider, limits);
                }

                drawing.push(<InputComponent
                    placeholder={placeholder}
                    onChange={onChange}
                    name={name}
                    type={type}
                    label={label}
                    required={required}
                    limits={limitsProvider}
                    disabled={disabled}
                    className={className}
                    isDeposit={isDeposit}
                    balance={userData ? userData.balance : null}
                    ifInitialValueDisabled={ifInitialValueDisabled}
                />);
                break;
            }
            case 'SelectComponent': {
                let { data, name, text, first, onChange, value, label, required, disabled, ifInitialValueDisabled, removeOption} = item.SelectComponent;
                data = this.extractDataForSelect(this.state.callData[data], value, text);

                if (onChange) {
                    onChange = this.findAssignedFunction(onChange);
                }
                
                if (first) {
                    data.unshift({ name: translation(first), value: '' });
                }

                if (removeOption && typeof removeOption.onClick == 'string') {
                    removeOption.onClick = this.findAssignedFunction(removeOption.onClick);
                }

                drawing.push(<SelectComponent
                    data={data}
                    onChange={onChange}
                    name={name}
                    label={label}
                    required={required}
                    disabled={disabled}
                    ifInitialValueDisabled={ifInitialValueDisabled}
                    removeOption={removeOption}
                />);
                break;
            }
            case 'ButtonComponent': {
                let { label, onClick, disabled, action, type, disabledCondition } = item.ButtonComponent;
                disabled = disabledCondition ? this.findDisabledCondition(disabledCondition) : disabled;

                if (onClick) {
                    onClick = this.findAssignedFunction(onClick);
                }

                if (action) {
                    action = this.findAssignedFunction(action);
                }
                
                drawing.push(<ButtonComponent
                    ref={type == 'submitting' ? this.toggleFormValid : null}
                    disabled={disabled}
                    label={label}
                    onClick={onClick}
                    type={type}
                />);
                break;
            }
            case 'GroupComponents': {
                this.drawComponents(item.GroupComponents);

                drawing.push(
                    <GroupComponents items={this.state.insideDrawing.map((item, index) => <Item item={item} key={index}></Item>)}></GroupComponents>
                );
                break;
            }
            case 'ConfirmDataComponent': {
                let data = this.findConfirmData();
                let { titleLabel, label, value } = item.ConfirmDataComponent;

                drawing.push(
                    <ConfirmDataComponent
                        titleLabel={titleLabel}
                        label={label}
                        value={value}

                        item={data.map((item, index) => <ConfirmRowComponent
                            label={item.label}
                            value={item.value}
                            key={index}
                        />)}
                    />
                );
                break;
            }

            case 'CmsComponent': {
                const { data } = item.CmsComponent;
                const { providerId } = this.props.paymentData;
                drawing.push(<CmsComponent item={this.state.callData[data + providerId]} />);
                break;
            }
            case 'LimitsComponent': {
                const { min, max, fee } = item.LimitsComponent;
                const { limits, currencies: [transactionCurrency] } = this.props.paymentData;

                if (limits) {
                    drawing.push(<LimitsComponent
                        min={limits.minAmount ? limits.minAmount : 1}
                        max={limits.maxTotalAmountOnAttempts ? limits.maxTotalAmountOnAttempts : undefined}
                        fee={limits.minFeeAmount ? limits.minFeeAmount : undefined}
                        feeLabel={fee}
                        minLabel={min}
                        maxLabel={max}
                        transactionCurrency={transactionCurrency}

                    />);
                }
                break;
            }
            case 'MenuComponent': {
                const { data } = item.MenuComponent;
                drawing.push(<MenuComponent items={this.state.callData[data]} />);
                break;
            }

            case 'LockComponent': {
                let { data, img, label } = item.LockComponent;
                data = this.state.callData[data];

                drawing.push(<LockComponent src={img} label={label} />);

                break;
            }

            case 'ConfirmOffersCanceledOnWithdrawalComponent': {
                let { data, titleLabel, label, value } = item.ConfirmOffersCanceledOnWithdrawalComponent;
                data = this.state.callData[data];

                if (data.length > 0) {
                    drawing.push(
                        <ConfirmOffersCanceledOnWithdrawalComponent
                            titleLabel={titleLabel}
                            label={label}
                            value={value}

                            item={data.map((item, index) => <ConfirmRowComponent
                                label={item.offerName}
                                value={item.value}
                                key={index}
                            />)}
                        />
                    );
                }

                break;
            }

            case 'InfoWonAmountToPayoutComponent': {
                let { data, labelLocked, labelUnlocked } = item.InfoWonAmountToPayoutComponent;
                const { transactionAmount, transactionCurrency } = this.state.callData[data];

                drawing.push(
                    <InfoWonAmountToPayoutComponent
                        transactionAmount={transactionAmount}
                        transactionCurrency={transactionCurrency}
                        labelLocked={labelLocked}
                        labelUnlocked={labelUnlocked}
                    />
                );
                break;
            }
            
            case 'WinningsBalanceComponent': {
                let { data, label } = item.WinningsBalanceComponent;
                const { value } = this.state.callData[data];
                const {currencies: [transactionCurrency]} = this.props.paymentData;

                drawing.push(
                    <WinningsBalanceComponent
                        label={label}
                        balance={value}
                        currency={transactionCurrency}
                    />
                );
                break;
            }

            case 'ReactSelectComponent': {
                let { data, name, text, first, onChange, value, label, required, disabled, isSearchable, ifInitialValueDisabled, removeOption} = item.ReactSelectComponent;
                data = this.extractDataForReactSelect(this.state.callData[data], value, text);


                if (removeOption && typeof removeOption.onClick == 'string') {
                    removeOption.onClick = this.findAssignedFunction(removeOption.onClick);
                }

                if (first) {
                    data.unshift({ label: translation(first), value: '' });
                }

                drawing.push(<ReactSelectComponent
                    data={data}
                    first={first}
                    onChange={onChange}
                    name={name}
                    label={label}
                    required={required}
                    disabled={disabled}
                    ifInitialValueDisabled={ifInitialValueDisabled}
                    removeOption={removeOption}
                    isSearchable={isSearchable}
                />);
                break;
            }
 
            default:
                console.log(`Payments, component ${Object.keys(item)[keyIndex]} not found!`);
        }

        return drawing;
    }

    findDisabledCondition = (data) => {
        switch (data) {
            case 'callWonAmountToPayout': {
                return !(this.state.callData[data].transactionAmount > 0);
            }
            default: {
                return false;
            }

        }

    }

    findConfirmData = () => {
        let { confrimData } = this.state;
        const { confirmUserData } = this.props.providerForm;
        let data = [];

        for (let item of confirmUserData) {
            if (Object.keys(item) in confrimData) {
                let temp = {};
                temp['label'] = item[Object.keys(item)].label;
                temp['value'] = confrimData[Object.keys(item)];

                data.push(temp);
            }
        }

        return data;
    }

    findPostData = (formData) => {

        let { providerForm: { isBettingShopTransaction }, paymentData, userData: { currencyCode, email, firstName, lastName } } = this.props;
        let { postData } = this.state;


        if ('providerId' in postData && postData.providerId == '') {
            this.combineData({ name: 'providerId', value: paymentData['providerId'] });
        }

        if ('transactionCurrency' in postData && postData.transactionCurrency == '') {
            this.combineData({ name: 'transactionCurrency', value: currencyCode });
        }

        if ('callbackUrl' in postData) {
            let { protocol, host } = window.location;

            const callbackUrl = protocol + "//" + host;
            if (postData.callbackUrl == '') {
                this.combineData({ name: 'callbackUrl', value: callbackUrl });
            } else if (!postData.callbackUrl.includes(window.location.protocol)) {
                this.combineData({ name: 'callbackUrl', value: callbackUrl + postData.callbackUrl });
            }
        }

        if ('phoneNumber' in formData && !formData.phoneNumber == '') {
            const phoneNumber = process.env.MOBILE_PREFIX + formData.phoneNumber;
            this.combineData({ name: 'phoneNumber', value: phoneNumber });
        }

        if ('isBettingShopTransaction' in postData && postData.isBettingShopTransaction == '') {
            this.combineData({ name: 'isBettingShopTransaction', value: isBettingShopTransaction });
        }

        if ('customer' in postData) {

            if ('email' in postData.customer && postData.customer.email == '') {
                this.combinePathData({ path: 'customer', name: 'email', value: email });
            }
            if ('firstName' in postData.customer && postData.customer.firstName == '') {
                this.combinePathData({ path: 'customer', name: 'firstName', value: firstName });
            }
            if ('lastName' in postData.customer && postData.customer.lastName == '') {
                this.combinePathData({ path: 'customer', name: 'lastName', value: lastName });
            }
        }

        if ('bank' in postData) {

            if ('customerName' in postData.bank && postData.bank.customerName == '') {
                this.combinePathData({ path: 'bank', name: 'customerName', value: firstName + ' ' + lastName });
            }

            if ('additionalInformationsJSON' in postData.bank) {
                let customerName = firstName + ' ' + lastName;
                this.combinePathData({ path: 'bank', name: 'additionalInformationsJSON', value: { customerName } });
            }

        }

        return formData;
    }

    setLimitsProvider = (limitsProvider, limits) => {

        if (limitsProvider && limits) {
            limitsProvider = limits;
        } else if (limits && 'onlyWinningsCanBePaidOut' in limits) {
            const { callWonAmountToPayout, callWonAmountToPayout: { success } } = this.state.callData;

            if (success && 'transactionAmount' in callWonAmountToPayout) {
                limitsProvider = {
                    maxTotalAmountOnAttempts: callWonAmountToPayout.transactionAmount
                }
            } else {
                limitsProvider = undefined;
            }

        } else {
            limitsProvider = undefined;
        }

        return limitsProvider;
    }

    findAssignedFunction = (name, providerId) => {
        switch (name) {
            case 'activeProvider':
                return this.activeProvider();

            case 'callCreditCardList':
                return this.getCreditCardList(name, providerId);

            case 'callPaystackBankList':
                return this.getPaystackBankList(name, providerId);

            case 'callBankList':
                return this.getBankList(name);

            case 'callAccountTypes':
                return this.getAccountTypes(name);

            case 'callMobilePhoneNumbers':
                return this.getCustomerMobileAccount(name);

            case 'callCustomerBankDetail':
                return this.getCustomerBankDetail(name);

            case 'callOffersCanceledOnWithdrawal':
                return this.getOffersCanceledOnWithdrawal(name);

            case 'callWinningsBalance':
                return this.getWinningsBalance(name);

            case 'phoneNumber':
                return this.extractMobileAccountNumber();

            case 'mobilePrefix':
                return process.env.MOBILE_PREFIX;

            case 'updatePostData':
                return this.updatePostData;

            case 'submit':
                return 'submit';

            case 'backStep':
                return this.backStep;

            case 'resetProvider':
                return this.resetProvider;

            case 'cmsPage':
                return this.getCmsPage(name, providerId);

            case 'callWonAmountToPayout':
                return this.getWonAmountToPayout(name, providerId);

            case 'removeCardConfirm':
                return this.removeCardConfirm;

            default:
                console.log(`Payments, function ${name} not found!`);
        }
    }

    removeCardConfirm = (selectedCard) => {
        const card = selectedCard ? selectedCard.split('->') : this.props.observedSelectorsValeus['card->cardType'].split('->');

        const [cardType, cardNumber] = card;
        addNotification(null, null, null, 
            <RemoveCardCardComponent 
                cardType={cardType} 
                cardNumber={cardNumber} 
                onClickAccept={this.removeCard} 
                onClickCancel={removeNotification}
            />);
    }

    removeCard = async(cardType) => {
        const [card] = this.state.callData['callCreditCardList'].filter(key => (key.cardType == cardType));
        try {
            const data = await this.props.dispatch(customerCreditCardUpdate({activeCard: false, creditCardId: card.creditCardId}));
            if (_.isEmpty(data)) {
                this.resetProvider();
            }
        } catch (error) {
            throw this.showLogAndNotification('removeCard', error);
        }
    }

    showLogAndNotification = (functionName, error) => {
        console.log(`Error in function ${functionName}!!! Error: ${error.message}`);
        addNotification('danger', translation(`payments_${error.message}`), translation('payments_generalError'));
    }

    activeProvider = () => {
        this.setState({ active: true });
    }

    getCreditCardList = async (name, providerId) => {
        const { dispatch } = this.props;

        try {
            let list = await dispatch(creditCardList(providerId))
            this.setState(state => (state.callData[name] = list, state));
        } catch (error) {
            throw this.showLogAndNotification('getCreditCardList', error);
        }
    }

    getCustomerBankDetail = async (name) => {
        const { dispatch} = this.props;

        try {
            let data = await dispatch(customerBankDetail())
            this.setState(state => (state.callData[name] = data, state));
        } catch (error) {
            this.showLogAndNotification('getCustomerBankDetail', error);
        }
    }

    getWinningsBalance = async (name) => {
        const { dispatch} = this.props;

        try {
            let data = await dispatch(winningsBalance())
            this.setState(state => (state.callData[name] = data, state));
        } catch (error) {
            this.showLogAndNotification('getWinningsBalance', error);
        }
    }

    getPaystackBankList = async (name, providerId) => {
        const { dispatch, callPaystackBankList } = this.props;

        try {
            if (!callPaystackBankList) {

                let list = await dispatch(paystackBankList(providerId))
                this.setState(state => (state.callData[name] = list, state));

            } else {
                this.setState(state => (state.callData[name] = callPaystackBankList, state));
            }

        } catch (error) {
            this.showLogAndNotification('getPaystackBankList', error);
        }
    }

    getBankList = async (name) => {
        const { dispatch, callBankList } = this.props;

        try {
            if (!callBankList) {

                let list = await dispatch(bankList())
                this.setState(state => (state.callData[name] = list, state));

            } else {
                this.setState(state => (state.callData[name] = callBankList, state));
            }

        } catch (error) {
            this.showLogAndNotification('getBankList', error);
        }
    }

    getCmsPage = async (name, providerId) => {
        const { dispatch } = this.props;
        try {
            const page = await dispatch(cmsPage(`paymentsprovider-${providerId}`));
            this.setState(state => (state.callData[name + providerId] = page, state));
        } catch (error) {
            this.showLogAndNotification('getCmsPage', error);
        }
    }

    getWonAmountToPayout = async (name, providerId) => {
        const { dispatch, userData: { currencyCode, userId } } = this.props;
        try {
            const obj = await dispatch(wonAmountToPayout({ providerId, transactionCurrency: currencyCode, customerId: userId }));
            this.setState(state => (state.callData[name] = obj, state));
        } catch (error) {
            this.showLogAndNotification('getWonAmountToPayout', error);
        }
    }

    getAccountTypes = async (name) => {
        const { dispatch, callAccountTypes } = this.props;

        try {
            if (!callAccountTypes) {

                let list = await dispatch(accountTypes())
                this.setState(state => (state.callData[name] = list, state));

            } else {
                this.setState(state => (state.callData[name] = callAccountTypes, state));
            }

        } catch (error) {
            this.showLogAndNotification('getAccountTypes', error);
        }
    }

    getCustomerMobileAccount = async (name) => {
        const { dispatch, callCustomerMobileAccount } = this.props;

        try {
            if (!callCustomerMobileAccount) {
                let list = await dispatch(customerMobileAccount())
                this.setState(state => (state.callData[name] = list, state));
            } else {
                this.setState(state => (state.callData[name] = callCustomerMobileAccount, state));
            }

        } catch (error) {
            this.showLogAndNotification('getCustomerMobileAccount', error);
        }
    }

    getOffersCanceledOnWithdrawal = async (name) => {
        const { dispatch, callOffersCanceledOnWithdrawal } = this.props;

        try {
            if (!callOffersCanceledOnWithdrawal) {

                let list = await dispatch(offersCanceledOnWithdrawal())
                this.setState(state => (state.callData[name] = list, state));

            } else {
                this.setState(state => (state.callData[name] = callOffersCanceledOnWithdrawal, state));
            }

        } catch (error) {
            this.showLogAndNotification('getOffersCanceledOnWithdrawal', error);
        }
    }

    backStep = async () => {
        await this.setState(this.getInitialState());
        await this.setState(this.drawComponents());
    }

    resetProvider = async () => {
        const { dispatch } = this.props;
        let { externalEvent: { resetProvider } } = this.props;
        resetProvider = false;

        await dispatch(reset(this.props.form));
        this.setState(this.getInitialState(reset));
        this.setState(this.drawComponents());
    }

    extractMobileAccountNumber = () => {
        return parseInt((new String(this.state.callData['callMobilePhoneNumbers'][0].mobileNumber).substr(3, 12)));
    }

    extractDataForInput = (inputData, name) => {
        return inputData[0][name];
    }

    extractDataForSelect = (selectData, value, name) => {
        let out = [];

        selectData.map((item) => {
            out.push({
                value: item[value] + '->' + item[name],
                name: item[name]
            })
        })

        return out;
    }

    extractDataForReactSelect = (selectData, value, name) => {
        let out = [];

        selectData.map((item) => {
            out.push({
                value: item[value] + '->' + item[name],
                label: item[name]
            })
        })

        return out;
    }

    checkProviderSettings = ({ mustBeLogged }) => {
        mustBeLogged = mustBeLogged ? true : false;

        return (
            {
                mustBeLogged: mustBeLogged
            }
        )
    } 

    render() {
        const { handleSubmit, hasError, isPending, onlyOne, isLogged, paymentData: { settings, providerId } } = this.props;
        const controller = app.getCurrentController();
        const { mustBeLogged } = this.checkProviderSettings(settings)

        if (hasError) {
            return <div>Error fallback component!</div>
        }

        if (!isLogged && controller != 'AgentDeposit' && mustBeLogged && !isPending) {
            return <NotLogged>{translation('payment_notLogged')}</NotLogged>
        }

        return (
            <S.ProviderContent
                onlyOne={onlyOne}
                method="post"
                autoComplete="off"
                className={'providerForm step-' + this.state.step}
                onSubmit={handleSubmit(this.sendData)}
                id={'providerId-' + providerId}
            >
                <Notifications />
                {this.state.drawing.map((item, index) => <Item item={item} key={index}></Item>)}
            </S.ProviderContent>
        );
    }
}

//////////////// VALIDATORS ////////////////

const validate = (values, constraints) => {
    const { providerId } = constraints.paymentData;
    return validation(values, validators['PROVIDER_' + providerId]);;
};

//////////////// ********* ////////////////

const mapStateToProps = (state, props) => {
    let { providerForm } = props;
    let callData = null;
    let callDataSize = null;
    let initialValues = {};

    if (providerForm && 'callData' in providerForm) {
        callData = providerForm.callData;
        Array.isArray(callData) ? callDataSize = callData.length : callDataSize = 1;
    }

    let { ProviderContent: {
        isPending,
        externalEvent,
        callCreditCardLis,
        callPaystackBankList,
        callBankList,
        callAccountTypes,
        callMobilePhoneNumbers,
        callCustomerBankDetail,
        callWonAmountToPayout,
        error },
        Offers: { callOffersCanceledOnWithdrawal },
        CmsPages: { cmsData }
    } = state;

    if (callData) {
        isPending = true;

        if (callCreditCardLis && callDataSize == 1) {
            isPending = false;
        }

        if (callPaystackBankList && callDataSize == 1) {
            isPending = false;
        }

        if (cmsData && callDataSize == 1) {
            isPending = false;
        }

        if (callCustomerBankDetail && callAccountTypes && callBankList && cmsData && callDataSize == 4) {

            const { accountNumber, accountType, bankId } = callCustomerBankDetail;
            initialValues["bank->accountNumber"] = accountNumber;
            callAccountTypes.map((item) => {
                if (item.accountType == accountType) {
                    initialValues["bank->accountType"] = accountType + '->' + item.accountTypeName;
                }
            })
            callBankList.map((item) => {
                if (item.bankId == bankId) {
                    initialValues["bank->bankId"] = bankId + '->' + item.bankName;
                }
            })

            isPending = false;
        }

        if (callCustomerBankDetail && callAccountTypes && callBankList && cmsData && callWonAmountToPayout && callDataSize == 5) {

            const { accountNumber, accountType, bankId } = callCustomerBankDetail;
            initialValues["bank->accountNumber"] = accountNumber;
            callAccountTypes.map((item) => {
                if (item.accountType == accountType) {
                    initialValues["bank->accountType"] = accountType + '->' + item.accountTypeName;
                }
            })
            callBankList.map((item) => {
                if (item.bankId == bankId) {
                    initialValues["bank->bankId"] = bankId + '->' + item.bankName;
                }
            })

            isPending = false;
        }

        if (callCustomerBankDetail && callBankList && callAccountTypes && callOffersCanceledOnWithdrawal) {
            const { accountNumber, accountType, bankId } = callCustomerBankDetail;

            initialValues["bank->accountNumber"] = accountNumber;
            callAccountTypes.map((item) => {
                if (item.accountType == accountType) {
                    initialValues["bank->accountType"] = accountType + '->' + item.accountTypeName;
                }
            })
            callBankList.map((item) => {
                if (item.bankId == bankId) {
                    initialValues["bank->bankId"] = bankId + '->' + item.bankName;
                }
            })

            isPending = false;
        }

        if (callMobilePhoneNumbers) {
            if (callMobilePhoneNumbers.length && 'mobileNumber' in callMobilePhoneNumbers[0]) {
                initialValues['phoneNumber'] = callMobilePhoneNumbers[0].mobileNumber.substr(3, 12);
            }
            isPending = false;
        }
    }

    if (!isPending) {
        return {
            isPending,
            externalEvent,
            error,
            callCreditCardLis,
            callPaystackBankList,
            callBankList,
            callAccountTypes,
            callMobilePhoneNumbers,
            callCustomerBankDetail,
            cmsData,
            callWonAmountToPayout,
            initialValues
        }
    } else {
        return {
            isPending,
            externalEvent,
            error,
            callCreditCardLis,
            callPaystackBankList,
            callBankList,
            callAccountTypes,
            callCustomerBankDetail,
            callMobilePhoneNumbers,
            cmsData,
            callWonAmountToPayout
        }
    }
};

ProviderContent = reduxForm({
    validate,
})(ProviderContent)


ProviderContent = connect(
    (state, props) => {
        if (props.providerForm && props.providerForm.observedSelectors) {
            const {observedSelectors} = props.providerForm; 
            const selector = formValueSelector(props.form);
            const observedSelectorsValeus = {};
            observedSelectors.map( item => {
                observedSelectorsValeus[item] = selector(state, item);
            })
            
            return {
                observedSelectorsValeus
            }
        } else {
            return {};
        }
    }
)(ProviderContent);



export default connect(mapStateToProps)(ProviderContent);

